/* eslint-disable import/no-cycle */
/* eslint-disable camelcase */
/* eslint-disable prettier/prettier */
/* eslint-disable no-use-before-define */
/* eslint-disable no-unused-vars */
import { apolloClient } from 'redux/sagas';
import { all, call, fork, put, takeEvery, takeLatest } from 'redux-saga/effects';
import { handleErrorMessage, setCurrentUser, updateCurrentUser } from 'helpers/Utils';
import { NotificationManager } from 'components/common/react-notifications';
import { ALL_ENTRIES_QUERY, DELETE_ENTRY_QUERY, DELETE_USER_QUERY, ENTRIES_SUMMARY_QUERY, POSTPONE_ENTRY_EXPIRATION_QUERY, UPDATE_PASSWORD_QUERY, UPDATE_PERSONAL_INFORMATION_QUERY } from './queries';
import { ALL_ENTRIES, DELETE_ENTRY, DELETE_ENTRY_SUCCESS, DELETE_USER, ENTRIES_SUMMARY, POSTPONE_ENTRY_EXPIRATION, UPDATE_PASSWORD, UPDATE_PERSONAL_INFORMATION } from '../contants';
import { allEntriesSuccess, deleteEntrySuccess, entriesSummarySuccess } from './actions';

export function* watchUpdatePersonalInformation() {
  yield takeEvery(UPDATE_PERSONAL_INFORMATION, updatePersonalInformationGenerator);
}
const UPDATE_PERSONAL_INFORMATION_API = async ( user ) => {
  return apolloClient.mutate({
    mutation: UPDATE_PERSONAL_INFORMATION_QUERY,
    variables: { name: user.name, secondaryEmail: user.secondary_email }
  });
}
function* updatePersonalInformationGenerator({ payload }) {
  const { user, history } = payload;
  try {
    const { data: { updatePersonalInformation }} = yield call(UPDATE_PERSONAL_INFORMATION_API, user);
    NotificationManager.success('Your personal information has been updated successfully!', 'Update Personal Information Success', 3000, null, null, '');
    updateCurrentUser(updatePersonalInformation);
  } catch (e) {
    handleErrorMessage(e.graphQLErrors[0], 'Update Personal Information Error', history);
  }
}

export function* watchUpdatePassword() {
  yield takeEvery(UPDATE_PASSWORD, updatePasswordGenerator);
}
const UPDATE_PASSWORD_API = async ( args ) => {
  return apolloClient.mutate({
    mutation: UPDATE_PASSWORD_QUERY,
    variables: { newPassword: args.new_password, currentPassword: args.current_password }
  });
}
function* updatePasswordGenerator({ payload }) {
  const { args, history } = payload;
  try {
    const { data: { updatePassword }} = yield call(UPDATE_PASSWORD_API, args);
    NotificationManager.success(updatePassword.message, 'Update Password Success', 3000, null, null, '');
  } catch (e) {
    handleErrorMessage(e.graphQLErrors[0], 'Update Password Error', history);
  }
}

export function* watchDeleteUser() {
  yield takeEvery(DELETE_USER, deleteUserGenerator);
}
const DELETE_USER_API = async ( args ) => {
  return apolloClient.mutate({
    mutation: DELETE_USER_QUERY,
    variables: { password: args.password }
  });
}
function* deleteUserGenerator({ payload }) {
  const { args, history } = payload;
  try {
    const { data: { deleteUser }} = yield call(DELETE_USER_API, args);
    NotificationManager.success(deleteUser.message, 'Delete Account Success', 3000, null, null, '');
    setCurrentUser(null);
    history.push('/user/login');
  } catch (e) {
    handleErrorMessage(e.graphQLErrors[0], 'Delete Account Error', history);
  }
}

export function* watchAllEntries() {
  yield takeEvery(ALL_ENTRIES, allEntriesGenerator);
}
const ALL_ENTRIES_API = async ( args ) => {
  return apolloClient.query({
    query: ALL_ENTRIES_QUERY,
    variables: { limit: 100, page: 1 }
  });
}
function* allEntriesGenerator({ payload }) {
  const { args } = payload;
  try {
    const { data: { allEntries }} = yield call(ALL_ENTRIES_API, args);
    yield put(allEntriesSuccess(allEntries));
  } catch (e) {
    handleErrorMessage(e.graphQLErrors[0], 'All Entries', null);
  }
}

export function* watchEntriesSummary() {
  yield takeLatest(ENTRIES_SUMMARY, entriesSummaryGenerator);
}
const ENTRIES_SUMMARY_API = async () => {
  return apolloClient.query({
    query: ENTRIES_SUMMARY_QUERY,
    variables: {}
  });
}
function* entriesSummaryGenerator() {
  try {
    const { data: { entriesSummary }} = yield call(ENTRIES_SUMMARY_API);
    yield put(entriesSummarySuccess(entriesSummary));
  } catch (e) {
    handleErrorMessage(e.graphQLErrors[0], 'Entries Summary', null);
  }
}

export function* watchDeleteEntry() {
  yield takeEvery(DELETE_ENTRY, deleteEntryGenerator);
}
const DELETE_ENTRY_API = async (args) => {
  return apolloClient.mutate({
    mutation: DELETE_ENTRY_QUERY,
    variables: {deleteEntryId: args.id}
  });
}
function* deleteEntryGenerator({payload}) {
  const { args } = payload;
  try {
    const { data: { deleteEntry }} = yield call(DELETE_ENTRY_API, args);
    NotificationManager.success(deleteEntry.message, 'Delete Entry Success', 3000, null, null, '');
    yield put(deleteEntrySuccess());
  } catch (e) {
    handleErrorMessage(e.graphQLErrors[0], 'Delete Entry Error', null);
  }
}

export function* watchDeleteEntrySuccess() {
  yield takeEvery(DELETE_ENTRY_SUCCESS, entriesSummaryGenerator)
}

export function* watchPostponeEntryExpiration() {
  yield takeEvery(POSTPONE_ENTRY_EXPIRATION, postponeEntryExpirationGenerator);
}
const POSTPONE_ENTRY_EXPIRATION_API = async (args) => {
  return apolloClient.mutate({
    mutation: POSTPONE_ENTRY_EXPIRATION_QUERY,
    variables: {postponeEntryExpirationId: args.id, expiresAt: args.expires_at}
  });
}
function* postponeEntryExpirationGenerator({payload}) {
  const { args } = payload;
  try {
    const { data: { postponeEntryExpiration }} = yield call(POSTPONE_ENTRY_EXPIRATION_API, args);
    NotificationManager.success(postponeEntryExpiration.message, 'Postpone Entry Expiration Success', 3000, null, null, '');
  } catch (e) {
    handleErrorMessage(e.graphQLErrors[0], 'Postpone Entry Expiration Error', null);
  }
}


export default function* rootSaga() {
  yield all([
    fork(watchUpdatePersonalInformation),
    fork(watchUpdatePassword),
    fork(watchPostponeEntryExpiration),
    fork(watchDeleteUser),
    fork(watchAllEntries),
    fork(watchEntriesSummary),
    fork(watchDeleteEntry),
    fork(watchDeleteEntrySuccess)
  ]);
  
}
