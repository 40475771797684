import { ALL_ENTRIES, ALL_ENTRIES_SUCCESS, DELETE_ENTRY, DELETE_ENTRY_SUCCESS, DELETE_USER, ENTRIES_SUMMARY, ENTRIES_SUMMARY_SUCCESS, POSTPONE_ENTRY_EXPIRATION, UPDATE_PASSWORD, UPDATE_PERSONAL_INFORMATION } from 'redux/contants';

const INIT_STATE = {
  loading: false,
  allEntries: null,
  entriesSummary: null,
  error: '',
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case UPDATE_PERSONAL_INFORMATION:
      return { ...state, loading: true, error: '' };
    case UPDATE_PASSWORD:
      return { ...state, loading: true, error: '' };
    case DELETE_USER:
      return { ...state, loading: true, error: '' };
    case ALL_ENTRIES:
      return { ...state, loading: true, error: '' };
    case POSTPONE_ENTRY_EXPIRATION:
      return { ...state, loading: true, error: '' };
    case DELETE_ENTRY:
      return { ...state, loading: true, error: '' };
    case DELETE_ENTRY_SUCCESS:
      return { ...state, loading: false, error: '' };
    case ALL_ENTRIES_SUCCESS:
      return { ...state, loading: false, error: '', allEntries: action.payload }
    case ENTRIES_SUMMARY:
        return { ...state, loading: true, error: '' };
    case ENTRIES_SUMMARY_SUCCESS:
        return { ...state, loading: false, error: '', entriesSummary: action.payload }
    default:
      return { ...state };
  }
};
