/* eslint-disable import/no-cycle */
/* eslint-disable prettier/prettier */
/* eslint-disable no-use-before-define */
/* eslint-disable no-unused-vars */
import { all, call, fork, put, takeEvery } from 'redux-saga/effects';
import { adminRoot } from 'constants/defaultValues';
import { setCurrentUser } from 'helpers/Utils';
import { apolloClient } from 'redux/sagas';
import { NotificationManager } from 'components/common/react-notifications';
import { LOGIN_USER_QUERY, READ_ENTRY_QUERY } from './queries';
import {
  LOGIN_USER,
  REGISTER_USER,
  LOGOUT_USER,
  FORGOT_PASSWORD,
  RESET_PASSWORD,
  READ_ENTRY,
} from '../contants';

import {
  loginUserSuccess,
  loginUserError,
  registerUserSuccess,
  registerUserError,
  forgotPasswordSuccess,
  forgotPasswordError,
  resetPasswordSuccess,
  resetPasswordError,
  readEntrySuccess,
  readEntryError,
} from './actions';

export function* watchLoginUser() {
  yield takeEvery(LOGIN_USER, loginUserGenerator);
}
const LOGIN_USER_API = async (user) => {
  return apolloClient.mutate({
    mutation: LOGIN_USER_QUERY,
    variables: {
      email: user.email,
      password: user.password
    }
  });
}
function* loginUserGenerator({ payload }) {
  const { user, history } = payload;
  try {
    const { data: { loginUser }} = yield call(LOGIN_USER_API, user);
    NotificationManager.success('Credentials verified. Welcome to SafeWire!', 'Login Success', 3000, null, null, '');
    setCurrentUser(loginUser);
    history.push(adminRoot);
  } catch (e) {
    NotificationManager.warning(e.graphQLErrors[0].message, 'Login Error', 3000, null, null, '');
  }
}

export function* watchReadEntry() {
  yield takeEvery(READ_ENTRY, readEntryGenerator);
}
const READ_ENTRY_API = async (args) => {
  return apolloClient.query({
    query: READ_ENTRY_QUERY,
    variables: {
      url: args.url
    }
  });
}
function* readEntryGenerator({ payload }) {
  const { args } = payload;
  try {
    const { data: { readEntry }} = yield call(READ_ENTRY_API, args);
    yield put(readEntrySuccess(readEntry));
  } catch (e) {
    NotificationManager.warning(e.graphQLErrors[0].message, e.graphQLErrors[0].extensions.code.replaceAll('_', ' '), 3000, null, null, '');
    yield put(readEntryError(e.graphQLErrors[0].message));
  }
}

// export function* watchRegisterUser() {
//   // eslint-disable-next-line no-use-before-define
//   yield takeEvery(REGISTER_USER, registerWithEmailPassword);
// }

// const registerWithEmailPasswordAsync = async (email, password) =>
//   // eslint-disable-next-line no-return-await
//   await auth
//     .createUserWithEmailAndPassword(email, password)
//     .then((user) => user)
//     .catch((error) => error);

// function* registerWithEmailPassword({ payload }) {
//   const { email, password } = payload.user;
//   const { history } = payload;
//   try {
//     const registerUser = yield call(
//       registerWithEmailPasswordAsync,
//       email,
//       password
//     );
//     if (!registerUser.message) {
//       const item = { uid: registerUser.user.uid, ...currentUser };
//       setCurrentUser(item);
//       yield put(registerUserSuccess(item));
//       history.push(adminRoot);
//     } else {
//       yield put(registerUserError(registerUser.message));
//     }
//   } catch (error) {
//     yield put(registerUserError(error));
//   }
// }

export function* watchLogoutUser() {
  yield takeEvery(LOGOUT_USER, logout);
}
const logoutAsync = async (history) => {
  setCurrentUser(null);
  history.push('/user/login');
};
function* logout({ payload }) {
  const { history } = payload;
  setCurrentUser();
  yield call(logoutAsync, history);
}

// export function* watchForgotPassword() {
//   // eslint-disable-next-line no-use-before-define
//   yield takeEvery(FORGOT_PASSWORD, forgotPassword);
// }

// const forgotPasswordAsync = async (email) => {
//   // eslint-disable-next-line no-return-await
//   return await auth
//     .sendPasswordResetEmail(email)
//     .then((user) => user)
//     .catch((error) => error);
// };

// function* forgotPassword({ payload }) {
//   const { email } = payload.forgotUserMail;
//   try {
//     const forgotPasswordStatus = yield call(forgotPasswordAsync, email);
//     if (!forgotPasswordStatus) {
//       yield put(forgotPasswordSuccess('success'));
//     } else {
//       yield put(forgotPasswordError(forgotPasswordStatus.message));
//     }
//   } catch (error) {
//     yield put(forgotPasswordError(error));
//   }
// }

// export function* watchResetPassword() {
//   // eslint-disable-next-line no-use-before-define
//   yield takeEvery(RESET_PASSWORD, resetPassword);
// }

// const resetPasswordAsync = async (resetPasswordCode, newPassword) => {
//   // eslint-disable-next-line no-return-await
//   return await auth
//     .confirmPasswordReset(resetPasswordCode, newPassword)
//     .then((user) => user)
//     .catch((error) => error);
// };

// function* resetPassword({ payload }) {
//   const { newPassword, resetPasswordCode } = payload;
//   try {
//     const resetPasswordStatus = yield call(
//       resetPasswordAsync,
//       resetPasswordCode,
//       newPassword
//     );
//     if (!resetPasswordStatus) {
//       yield put(resetPasswordSuccess('success'));
//     } else {
//       yield put(resetPasswordError(resetPasswordStatus.message));
//     }
//   } catch (error) {
//     yield put(resetPasswordError(error));
//   }
// }

export default function* rootSaga() {
  yield all([
    fork(watchLoginUser),
    fork(watchLogoutUser),
    fork(watchReadEntry)
    // fork(watchRegisterUser),
    // fork(watchForgotPassword),
    // fork(watchResetPassword),
  ]);
}
