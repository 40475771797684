const { gql } = require("@apollo/client");

const UPDATE_PERSONAL_INFORMATION_QUERY = gql(`
    mutation($name: String!, $secondaryEmail: String!){
        updatePersonalInformation(name: $name, secondary_email: $secondaryEmail) {
            id, secondary_email, name
        }
    }`
);

const UPDATE_PASSWORD_QUERY = gql(`
    mutation($newPassword: String!, $currentPassword: String!){
        updatePassword(new_password: $newPassword, current_password: $currentPassword) {
            status, message
        }
    }`
);

const DELETE_USER_QUERY = gql(`
    mutation($password: String!){
        deleteUser(password: $password) {
            status, message
        }
    }`
);

const ALL_ENTRIES_QUERY = gql(`
    query($limit: Int, $page: Int, $search: String, $orderByField: OrderByField, $orderDirection: OrderDirection){
        allEntries(limit: $limit, page: $page, search: $search, orderByField: $orderByField, orderDirection: $orderDirection) {
            rows {
                nickname, id, salt, url, expires_at, created_at, template
            }
            count, page, allPages
        }
    }`
);

const POSTPONE_ENTRY_EXPIRATION_QUERY = gql(`
    mutation($postponeEntryExpirationId: ID!, $expiresAt: String!){
        postponeEntryExpiration(id: $postponeEntryExpirationId, expires_at: $expiresAt) {
            status, message
        }
    }`
);

const ENTRIES_SUMMARY_QUERY = gql(`
    query{
        entriesSummary {
            activeEntries, deletedEntries, expiredEntries
        }
    }`
);

const DELETE_ENTRY_QUERY = gql(`
    mutation($deleteEntryId: ID!){
        deleteEntry(id: $deleteEntryId) {
            status, message
        }
    }`
);

module.exports = { DELETE_ENTRY_QUERY, ENTRIES_SUMMARY_QUERY, ALL_ENTRIES_QUERY, UPDATE_PERSONAL_INFORMATION_QUERY, UPDATE_PASSWORD_QUERY, DELETE_USER_QUERY, POSTPONE_ENTRY_EXPIRATION_QUERY } 