const { gql } = require("@apollo/client");

const LOGIN_USER_QUERY = gql(`
    mutation LoginUser($email: String!, $password: String!){
        loginUser(email: $email, password: $password){
            user{
                name, id, is_email_verified, deleted_at, secondary_email
            },
            token, encryption_key, encryption_iv
        }
    }`
);

const READ_ENTRY_QUERY = gql(`
    query($url: String!){
        readEntry(url: $url) {
            id, nickname, url, salt, expires_at, json_payload, template
        }
    }`
);

module.exports = { LOGIN_USER_QUERY, READ_ENTRY_QUERY } 