/* eslint-disable import/no-cycle */
/* eslint-disable camelcase */
/* eslint-disable prettier/prettier */
/* eslint-disable no-use-before-define */
/* eslint-disable no-unused-vars */
import { adminRoot } from 'constants/defaultValues';
import { apolloClient } from 'redux/sagas';
import { all, call, fork, takeEvery } from 'redux-saga/effects';
import { handleErrorMessage } from 'helpers/Utils';
import { NotificationManager } from 'components/common/react-notifications';
import { CREATE_ENTRY } from 'redux/contants';
import { CREATE_ENTRY_QUERY } from './queries';

export function* watchCreateEntry() {
  yield takeEvery(CREATE_ENTRY, createEntryGenerator);
}
const CREATE_ENTRY_API = async ( entry ) => {
  return apolloClient.mutate({
    mutation: CREATE_ENTRY_QUERY,
    variables: {
      nickname: entry.nickname,
      salt: entry.salt,  
      url: entry.url,
      jsonPayload: entry.json_payload,  
      template: entry.template,
      expiresAt: entry.expires_at,  
      encryptionVersion: entry.encryption_version,
      hashVersion: entry.hash_version,
      compoundKeyVersion: entry.compound_key_version
    }
  });
}
function* createEntryGenerator({ payload }) {
  const { entry, history } = payload;
  try {
    const { data: { createEntry }} = yield call(CREATE_ENTRY_API, entry);
    NotificationManager.success('Your secure entry has been created successfully!', 'Create Entry Success', 3000, null, null, '');
    history.push(`${adminRoot}/entries/generate/${createEntry.url}`);
  } catch (e) {
    handleErrorMessage(e.graphQLErrors[0], 'Create Entry Error', history);
  }
}

export default function* rootSaga() {
  yield all([
    fork(watchCreateEntry)
  ]);
}
