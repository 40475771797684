const { gql } = require("@apollo/client");

const CREATE_ENTRY_QUERY = gql(`
  mutation($nickname: String!, $salt: String!, $url: String!, $jsonPayload: String!, $template: String!, $expiresAt: String!, $encryptionVersion: Int!, $hashVersion: Int!, $compoundKeyVersion: Int!){
    createEntry(nickname: $nickname, salt: $salt, url: $url, json_payload: $jsonPayload, template: $template, expires_at: $expiresAt, encryption_version: $encryptionVersion, hash_version: $hashVersion, compound_key_version: $compoundKeyVersion) {
      id, nickname, url, salt, json_payload, expires_at
    }
  }`
);

module.exports = { CREATE_ENTRY_QUERY } 