import { all } from 'redux-saga/effects';
import { ApolloClient, InMemoryCache, createHttpLink } from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import { getCurrentUser } from 'helpers/Utils';
import authSagas from './auth/saga';
import entriesSagas from './entries/saga'
import todoSagas from './todo/saga';
import userSagas from './user/saga';
import chatSagas from './chat/saga';
import surveyListSagas from './surveyList/saga';
import surveyDetailSagas from './surveyDetail/saga';

const httpLink = createHttpLink({
  uri: process.env.REACT_APP_BASE_URL,
});

const authLink = setContext((_, { headers }) => {
  const context = getCurrentUser();
  return {
    headers: {
      ...headers,
      authorization: context?.token ? `${context?.token}` : '',
    }
  }
});

export const apolloClient = new ApolloClient({
  link: authLink.concat(httpLink),
  cache: new InMemoryCache()
});

export default function* rootSaga() {
  yield all([
    authSagas(),
    userSagas(),
    todoSagas(),
    entriesSagas(),
    chatSagas(),
    surveyListSagas(),
    surveyDetailSagas(),
  ]);
}
