/* eslint-disable import/prefer-default-export */
import { ALL_ENTRIES, ALL_ENTRIES_SUCCESS, DELETE_ENTRY, DELETE_ENTRY_SUCCESS, DELETE_USER, ENTRIES_SUMMARY, ENTRIES_SUMMARY_SUCCESS, POSTPONE_ENTRY_EXPIRATION, UPDATE_PASSWORD, UPDATE_PERSONAL_INFORMATION } from '../contants';

export const updatePersonalInformation = (user, history) => ({
  type: UPDATE_PERSONAL_INFORMATION,
  payload: { user, history },
});

export const updatePassword = (args) => ({
  type: UPDATE_PASSWORD,
  payload: { args },
});

export const deleteUser = (args, history) => ({
  type: DELETE_USER,
  payload: { args, history },
});

export const allEntries = (args) => ({
  type: ALL_ENTRIES,
  payload: { args },
});

export const allEntriesSuccess = (entries) => ({
  type: ALL_ENTRIES_SUCCESS,
  payload: entries,
});

export const entriesSummary = () => ({
  type: ENTRIES_SUMMARY,
  payload: {},
});

export const entriesSummarySuccess = (summary) => ({
  type: ENTRIES_SUMMARY_SUCCESS,
  payload: summary,
});

export const deleteEntry = (args) => ({
  type: DELETE_ENTRY,
  payload: { args },
});

export const postponeEntryExpiration = (args) => ({
  type: POSTPONE_ENTRY_EXPIRATION,
  payload: { args },
});

export const deleteEntrySuccess = () => ({
  type: DELETE_ENTRY_SUCCESS,
  payload: {},
});
